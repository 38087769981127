
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import { VueDraggableNext } from 'vue-draggable-next';
import { ProgramSessionQuestionType } from '@/entities/Program/ProgramSessionQuestionType';
import { ProgramSessionQuestionsItem } from '@/entities/Program/ProgramSessionQuestionsItem';
import { ProgramItem } from '@/entities/Program/ProgramItem';
import SessionQuestionsProgramListing from '@/components/Program/SessionQuestions/SessionQuestionsProgramListing.vue';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import { ProgramSessionQuestionOption } from '@/entities/Program/ProgramSessionQuestionOption';
import loading from '@/components/Common/loading.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { StoreHelper } from '@/store/StoreHelper';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { ProgramSessionQuestionsSignalRModel } from '@/signalRRequest/Program/ProgramSessionQuestionsSignalRModel';
import Common from '@/helper/Common';
import { FetchData, Root, Store } from '@/main';
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import { reactive } from 'vue';
import ApiContentType from '@/enums/ApiContentType';
@Component({
  components: {
    draggable: VueDraggableNext,
    SessionQuestionsProgramListing,
    loading,
    ModalPopup,
    ValidationSummary,
  },
})
class SessionQuestionsEditItemPane extends Vue {
  @Prop() private selectedSessionQuestion?: ProgramSessionQuestionsItem;
  @Prop() private programItems?: ProgramItem[];
  @Prop() private programQuestionTypes?: ProgramSessionQuestionType[];
  private localSessionQuestion?: ProgramSessionQuestionsItem = ProgramSessionQuestionsItem.createEmpty();
  private localprogramQuestionTypes?: ProgramSessionQuestionType[] = [];
  private selectedOptions: ProgramSessionQuestionOption[] = [];
  private excelIcon: string = require('@/assets/Images/Excel.jpeg');
  private deepmerge = require('deepmerge'); // for deep copy
  private isPollingTypeQuestion = false;
  private dragging = false;
  private allowedCharacters: string[] = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  private selectedProgramIds: number[] = [];
  private selectAllProgramItems = false;
  private showLoading = false;
  private maxOptionsAllowed = 26;
  private pollingTypeQuestionType = 2;
  private loaderBorderColor = '';
  private fileDownload = require('js-file-download');
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupContent: string = this.getRemoveSessionQuestionMessage();
  private errors: string[] = [];
  private messages: string[] = [];
  private optionAddCounter = 0;
  // validation summary
  private visibleValidationSummary = false;
  private validationErrorFields: string[] = [];
  private beforeEditingCache: ProgramSessionQuestionsItem = ProgramSessionQuestionsItem.createEmpty();
  private isMobileView = false;
  private deleteIcon = require('@/assets/Images/Delete-icon.svg');
  private dragDropIcon = require('@/assets/Images/drag-and-drop-icon.svg');
  private state = reactive({
    localSessionQuestion: undefined as ProgramSessionQuestionsItem | undefined,
  });

  private rules = {
    localSessionQuestion: {
      questionTitle: {
        required,
        minLength: minLength(1),
      },
    },
  };
  private status(validation: { $error: any; $dirty: any }) {
    return {
      error: validation.$error,
      dirty: validation.$dirty,
    };
  }
  private v$ = useVuelidate(this.rules, this.state);
  // Validation summary
  private CloseValidationSummary(visible: boolean) {
    this.visibleValidationSummary = visible;
  }
  // Created Event of Component
  private created() {
    // Assign value to the local variable of Session Question
    if (this.selectedSessionQuestion != null) {
      this.localSessionQuestion = this.deepmerge({}, this.selectedSessionQuestion);
      this.beforeEditingCache = this.deepmerge(
        {},
        this.selectedSessionQuestion,
      );
      this.state.localSessionQuestion = this.localSessionQuestion;
    }
  }
  // Mounted Event of Component
  private mounted() {
    this.ClearMessagesList();
    // Assign value to the local variable of Session Question
    if (this.selectedSessionQuestion != null) {
      this.localSessionQuestion = this.deepmerge({}, this.selectedSessionQuestion);
      this.beforeEditingCache = this.deepmerge(
        {},
        this.selectedSessionQuestion,
      );
    }
    // Assign value to the local variable of Program Question Types to show in dropdown
    if (this.programQuestionTypes != null) {
      this.localprogramQuestionTypes = this.programQuestionTypes;
    }
    if (this.localSessionQuestion != null) {
      // Empty options
      this.selectedOptions = [];
      this.selectAllProgramItems = false;
      this.selectedProgramIds = [];
      // In case of Polling Type Session Question
      if (
        this.localSessionQuestion.ProgramQuestionType ===
        this.pollingTypeQuestionType
      ) {
        this.isPollingTypeQuestion = true;
        this.selectedOptions = JSON.parse(JSON.stringify(this.localSessionQuestion.programQuestionOptions));
        if (this.selectedOptions.length === 0) {
          this.AddNewOption();
        }
      }
      if (
        this.localSessionQuestion.attachedProgramIds.length ===
        this.localSessionQuestion.allProgramIds.length
      ) {
        this.selectAllProgramItems = true;
      }
      // Set selected program Ids from list of attached program Ids
      this.selectedProgramIds = this.localSessionQuestion.attachedProgramIds;
      this.state.localSessionQuestion = this.localSessionQuestion;
    }

    // check for ipad and mobile view
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    // make scroll to top when session question item selected
    Root.on(
      'new-session-question-edit-item-pane',
      (isNewSessionQuestionEditItemPane: boolean) => {
        if (isNewSessionQuestionEditItemPane) {
          // scroll to top if any program edit or new program clicked
          if (!this.isMobileView) {
            Common.ScrollToTop('sessionQuestionEditMode');
          }
        }
      },
    );
    const self = this;
    Root.on(
      'program-session-questions-updated',
      (sessionQuestionsItem: ProgramSessionQuestionsItem) => {
        self.UpdateLocalSessionQuestion(self, sessionQuestionsItem);
      },
    );

  }
  // Add new Option to array
  private AddNewOption() {
    this.ClearErrorsList();
    this.ClearMessagesList();
    if (this.optionAddCounter === 0) {
      this.optionAddCounter = this.selectedOptions.length;
    }
    const alreadyAddedOptionsCount = this.selectedOptions.length;
    if (alreadyAddedOptionsCount < this.maxOptionsAllowed) {
      const str = this.allowedCharacters[this.optionAddCounter];
      const item = ProgramSessionQuestionOption.createEmpty();
      item.optionName = this.localSessionQuestion!.id > 0 ? '' : str;
      item.id = this.optionAddCounter;
      item.sortOrder = this.optionAddCounter + 1;
      this.selectedOptions.push(item);
      this.optionAddCounter = this.optionAddCounter + 1;
    } else {
      // Max Limit is reached
      this.errors.push(
        this.$t('SessionQuestions.MaxOptionsReached').toString(),
      );
    }
    this.localSessionQuestion!.programQuestionOptions = this.selectedOptions;
  }
  // Remove an option from array
  private RemoveOption(selected: any) {
    this.ClearErrorsList();
    this.ClearMessagesList();
    const objIndex = this.selectedOptions.findIndex(
      (obj: any) => obj.id === selected.id,
    );
    if (objIndex > -1) {
      this.selectedOptions.splice(objIndex, 1);
    }
    this.localSessionQuestion!.programQuestionOptions = this.selectedOptions;
  }

  // On change of Question type
  private OnQuestiontypeChange(event: any) {
    this.ClearErrorsList();
    this.ClearMessagesList();
    // If polling Type
    if (Number(event.target.value) === this.pollingTypeQuestionType) {
      this.isPollingTypeQuestion = true;
      this.selectedOptions = [];
      this.AddNewOption();
    } else {
      this.isPollingTypeQuestion = false;
    }
  }
  // Draggable component onMove Event
  private checkMove() {
    // Write if something required on move
  }
  // Draggable component on drag start event
  private draggingStart() {
    this.dragging = true;
  }
  // Draggable component on drag end event
  private draggingEnd() {
    this.dragging = false;
    this.localSessionQuestion!.programQuestionOptions = this.selectedOptions;
  }
  // Export Session Questions Data for a particular Session Question
  private ExportSingleProgramQuestionResultToExcel() {
    this.showLoading = true;
    FetchData('/FileDownload/ExportProgramQuestion',JSON.stringify({
      programQuestionId: this.localSessionQuestion!.id,
    }),ApiContentType.Excel)
      .then((response) => {
        this.fileDownload(response, 'ProgramQuestions.xlsx');
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
  private SetSessionQuestionIsGlobalProgramQuestion(val: boolean) {
    this.localSessionQuestion!.globalProgramQuestion = val;
  }

  private UpdateLocalSessionQuestion(self: any, val: ProgramSessionQuestionsItem) {
    self.$v.$reset();
    // hide validation summary when switching the evaluation item
    self.visibleValidationSummary = false;
    self.localSessionQuestion = this.deepmerge({}, val);
    self.beforeEditingCache = this.deepmerge({}, val);
    self.selectedOptions = [];
    self.selectAllProgramItems = false;
    self.selectedProgramIds = [];
    if (
      self.localSessionQuestion!.ProgramQuestionType ===
      self.pollingTypeQuestionType
    ) {
      self.isPollingTypeQuestion = true;
      self.selectedOptions = JSON.parse(JSON.stringify(self.localSessionQuestion!.programQuestionOptions));
    } else {
      self.isPollingTypeQuestion = false;
    }
    if (
      self.localSessionQuestion!.attachedProgramIds.length ===
      self.localSessionQuestion!.allProgramIds.length
    ) {
      self.selectAllProgramItems = true;
    }
    self.selectedProgramIds = self.localSessionQuestion!.attachedProgramIds;
    self.state.localSessionQuestion = self.localSessionQuestion;
  }
  // Watch when the selected session question is updated from list
  @Watch('selectedSessionQuestion')
  private onPropertySelectedSessionQuestionChange(
    val: ProgramSessionQuestionsItem,
  ) {
    // hide validation summary when switching the evaluation item
    this.visibleValidationSummary = false;
    this.localSessionQuestion = this.deepmerge({}, val);
    this.beforeEditingCache = this.deepmerge({}, val);
    this.selectedOptions = [];
    this.selectAllProgramItems = false;
    this.selectedProgramIds = [];
    if (
      this.localSessionQuestion!.ProgramQuestionType ===
      this.pollingTypeQuestionType
    ) {
      this.isPollingTypeQuestion = true;
      this.selectedOptions = JSON.parse(JSON.stringify(this.localSessionQuestion!.programQuestionOptions));
    } else {
      this.isPollingTypeQuestion = false;
    }
    if (
      this.localSessionQuestion!.attachedProgramIds.length ===
      this.localSessionQuestion!.allProgramIds.length
    ) {
      this.selectAllProgramItems = true;
    }
    this.selectedProgramIds = this.localSessionQuestion!.attachedProgramIds;
    this.state.localSessionQuestion = this.localSessionQuestion;
  }
  // Click on Cancel button of Add/Edit Item
  private Cancel(val: any) {
    this.ClearErrorsList();
    this.ClearMessagesList();
    val.$reset();
    const existingitem = this.beforeEditingCache;
    this.$emit('program-sessionQuestion-item-cancel-operation', existingitem);
    Root.emit(
      'program-sessionQuestion-item-cancel-operation',
      existingitem,
    );
  }

  // Get message to delete from Translations
  private getRemoveSessionQuestionMessage(): string {
    return this.$t('SessionQuestions.DeleteSessionQuestion').toString();
  }

  // Click on Yes/Cancel Button of Popup
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      this.DeleteConfirmed();
    } else {
      return;
    }
  }

  // Show Popup for Deletion
  private Delete() {
    this.modalPopupVisible = true;
  }
  // Delete a Session Question
  private DeleteConfirmed() {
    const signalObject = this.SendNotificationRequest(true);
    FetchData('/Program/DeleteProgramQuestion',
      JSON.stringify({
        programQuestionId: this.localSessionQuestion!.id,
      }))
      .then((response) => {
        if (response.ValidationErrors.length > 0) {
          // Server side validation failuere
          alert(this.$t('Message.UnableToDelete'));
        } else {
          // Successful update / save
          this.SendNotificationRequestToQueue(
            signalObject,
            this.localSessionQuestion!,
          );
          Root.emit(
            'program-sessionquestion-item-delete-operation',
            this.localSessionQuestion!.id,
          );
        }
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
  // Validate Polling type Question Options
  private ValidatePollingtypeQuestionOptions() {
    if (
      this.localSessionQuestion!.ProgramQuestionType ===
      this.pollingTypeQuestionType
    ) {
      if (this.selectedOptions.length < 2) {
        this.errors.push(
          this.$t('SessionQuestions.MinimumRequiredOptions').toString(),
        );
      } else {
        const emptyOptions: ProgramSessionQuestionOption[] = [];
        this.selectedOptions.forEach((item) => {
          if (item.optionName.trim() === '') {
            emptyOptions.push(item);
            item.defaultStyling = 'background: #fff;border: solid #FF0000 2px;';
          }
        });

        if (emptyOptions.length > 0) {
          this.errors.push(this.$t('SessionQuestions.SelectedOptions').toString());
        }
      }
    }
  }
  // Get styling of Options textbox for validations
  private GetTextboxStyling(option: ProgramSessionQuestionOption): string {
    return option.defaultStyling;
  }

  // Check if option Name is empty
  private CheckIfEmptyOption(option: ProgramSessionQuestionOption) {
    this.ClearErrorsList();
    this.ClearMessagesList();
    if (option.optionName.trim() === '') {
      option.defaultStyling = 'background-color:#FFF;border: solid #FF0000 2px;';
    } else {
      option.defaultStyling = '';
    }
    const objIndex = this.selectedOptions.findIndex(
      (obj: ProgramSessionQuestionOption) => obj.id === option.id,
    );
    if (objIndex > -1) {
      // Updated
      this.selectedOptions[objIndex] = option;
    }
    this.localSessionQuestion!.programQuestionOptions = this.selectedOptions;
  }
  // Clear Error List
  private ClearErrorsList() {
    this.errors = [];
  }

  // Clear Messages List
  private ClearMessagesList() {
    this.messages = [];
  }
  private FillValidationErrors() {
    const self = this;
    this.errors.forEach((item) => {
      self.validationErrorFields.push(item);
    });
  }
  // Add/ Edit a Session Question
  private async Submit(val: any) {
    val.$touch();
    this.validationErrorFields = [];
    this.ClearErrorsList();
    this.ClearMessagesList();
    this.ValidatePollingtypeQuestionOptions();
    const res = !await val.$validate();
    if (res) {
      if (val.localSessionQuestion.questionTitle!.$error === true) {
        this.validationErrorFields.push(this.$t('SessionQuestions.Question').toString());
      }
      if (this.errors.length) {
        this.FillValidationErrors();
      }
      this.visibleValidationSummary = true;
      return;
    }
    val.$reset();
    if (this.errors.length) {
      this.FillValidationErrors();
      this.visibleValidationSummary = true;
      return;
    }

    this.localSessionQuestion!.programQuestionOptions = this.selectedOptions;
    const storeHelper: StoreHelper = new StoreHelper(Store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();

    this.localSessionQuestion!.ApplicationId = reqInfo.ApplicationId;
    this.localSessionQuestion!.ApplicationInstanceId = reqInfo.ApplicationInstanceId;
    const signalObject = this.SendNotificationRequest(false);
    this.visibleValidationSummary = false;
    // No errors submitting.
    FetchData('/Program/EditProgramQuestion', JSON.stringify({ programQuestionJSON: JSON.stringify(this.localSessionQuestion)}))
      .then((response) => {
        if (response.ValidationErrors.length > 0) {
          // Server side validation failuere
          alert(this.$t(response.ValidationErrors[0]));
        } else {
          // Successful update / save
          const item: ProgramSessionQuestionsItem = response.SessionQuestionItem;
          if (response.Link !== '') {
            Root.emit('program-sessionquestions-set-publishlink-expirationDate', {
              Link : response.Link,
              ExpirationDate :  response.ExpirationDate,
            });
          }
          this.SendNotificationRequestToQueue(
            signalObject,
            item,
          );
        }
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
  // Set selected program Items
  private SetSelectedProgramItems(selectedProgramItems: ProgramItem[]) {
    this.selectedProgramIds = [];
    selectedProgramItems.forEach((element) => {
      if (this.selectedProgramIds.indexOf(element.Id) === -1) {
        this.selectedProgramIds.push(element.Id);
      }
    });
    this.localSessionQuestion!.attachedProgramIds = this.selectedProgramIds;
  }

  private SendNotificationRequest(isDelete: boolean): SignalRModelRequest {
    setTimeout(() => {
      Root.emit('show-notification-popup', true);
      Root.emit('show-notification-loading', true);
    }, 100);
    Root.emit('program-sessionquestion-tab-select-operation');
    const signalR: ProgramSessionQuestionsSignalRModel = new ProgramSessionQuestionsSignalRModel(
      Root,
      Store,
      this.$t,
    );
    let signalObject: SignalRModelRequest = new SignalRModelRequest();
    if (isDelete) {
      signalObject = signalR.SingleDeleteSessionQuestionSignalRModelRequest;
      const arr: number[] = [];
      arr.push(this.localSessionQuestion!.id);
      signalObject.SelectedIds = arr;
      signalObject.Description = this.$t(signalObject.SubmitMessage, {
        selectedCount: arr.length, // Just single item delete
        user: signalR.GetUserName(signalObject, this.$t('UserName.You').toString()),
      }).toString();
    } else {
      if (this.localSessionQuestion!.id === 0) {
        signalObject = signalR.SingleCreateSessionQuestionSignalRModelRequest;
      } else {
        signalObject = signalR.SingleUpdateSessionQuestionSignalRModelRequest;
        const arr: number[] = [];
        arr.push(this.localSessionQuestion!.id);
        signalObject.SelectedIds = arr;
      }
      signalObject.Description = this.$t(signalObject.SubmitMessage, {
        user: signalR.GetUserName(signalObject, this.$t('UserName.You').toString()),
      }).toString();
    }
    const storeHelper: StoreHelper = new StoreHelper(Store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    signalObject.ApplicationId = Number(reqInfo.ApplicationId);
    signalObject.EventId = Number(reqInfo.ApplicationInstanceId);
    signalObject.JSONData = JSON.stringify({selectedName: this.localSessionQuestion!.questionTitle});
    signalR.DisableSessionQuestionsAndAddToNotificationPopup(signalObject);
    return signalObject;
  }

  private SendNotificationRequestToQueue(
    signalObject: SignalRModelRequest,
    sessionQuestion: ProgramSessionQuestionsItem,
  ) {
    const signalR: ProgramSessionQuestionsSignalRModel = new ProgramSessionQuestionsSignalRModel(
      Root,
      Store,
      this.$t,
    );
    // Case of Single Create as we get the id once response come from DB
    if (signalObject.SelectedIds.length === 0) {
      const arr: number[] = [];
      arr.push(sessionQuestion.id);
      signalObject.SelectedIds = arr;
    }
    signalObject.JSONData = signalR.GetSessionQuestionItemToSendToSignalR(sessionQuestion);
    signalR.PerformSessionSingleOperation(signalObject);
  }
}
export default toNative(SessionQuestionsEditItemPane);
