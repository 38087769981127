import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelRadio as _vModelRadio, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "item-listing" }
const _hoisted_2 = {
  id: "dvAvailableFor",
  class: "position-relative"
}
const _hoisted_3 = { class: "font-size-16" }
const _hoisted_4 = {
  key: 0,
  class: "ajax-loader grid-listing"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  id: "dvSelectedOptionForParticipant",
  class: ""
}
const _hoisted_7 = { id: "dvAllParticipant" }
const _hoisted_8 = { class: "description margin-top-0" }
const _hoisted_9 = { class: "margin-left-5" }
const _hoisted_10 = { id: "dvSelectedParticipant" }
const _hoisted_11 = { class: "description margin-top-0" }
const _hoisted_12 = ["disabled"]
const _hoisted_13 = { class: "display-flex" }
const _hoisted_14 = {
  key: 0,
  class: "position-relative"
}
const _hoisted_15 = { class: "showDvSelectedGroups margin-left-20" }
const _hoisted_16 = ["index"]
const _hoisted_17 = ["id", "value", "onClick"]
const _hoisted_18 = { class: "margin-left-5 description" }
const _hoisted_19 = {
  key: 0,
  class: "margin-left-5 description"
}
const _hoisted_20 = {
  key: 1,
  class: "margin-left-5 description"
}
const _hoisted_21 = {
  key: 0,
  class: "position-relative"
}
const _hoisted_22 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("Program.ListOfParticipantGroups.AvailableFor")), 1),
      _createElementVNode("i", {
        class: "margin-left-5 fa fa-info-circle information-icon",
        onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getInformationString(true, 'Available for'))),
        onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getInformationString(false,'Available for')))
      }, null, 32),
      _withDirectives(_createElementVNode("div", { class: "information-icon-divv" }, _toDisplayString(_ctx.$t("Program.ListOfParticipantGroups.ChooseYourAudienceAsRequied")), 513), [
        [_vShow, _ctx.showInformation]
      ])
    ]),
    (_ctx.localLinkedProgramLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("img", {
            class: "img-responsive ajax-loader-img",
            src: _ctx.loadingImage,
            alt: "Wait"
          }, null, 8, _hoisted_5)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("label", _hoisted_8, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.hasSelectedGroups) = $event)),
                type: "radio",
                name: "selectedOptionForParticipant",
                value: false,
                onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectedOptionChange()))
              }, null, 544), [
                [_vModelRadio, _ctx.hasSelectedGroups]
              ]),
              _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("Program.ListOfParticipantGroups.ApplyToAllParticipant")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("label", _hoisted_11, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.hasSelectedGroups) = $event)),
                type: "radio",
                name: "selectedOptionForParticipant",
                value: true,
                disabled: !_ctx.isDisableCheckBox(),
                onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.selectedOptionChange()))
              }, null, 40, _hoisted_12), [
                [_vModelRadio, _ctx.hasSelectedGroups]
              ]),
              _createElementVNode("label", {
                class: _normalizeClass(["margin-left-5", {'color-darkgray': !_ctx.isDisableCheckBox()}])
              }, _toDisplayString(_ctx.$t("Program.ListOfParticipantGroups.ApplyToSelectedParticipantGroups")), 3)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("p", {
                class: _normalizeClass(["subheadingitalic width80", {'color-darkgray': !_ctx.isDisableCheckBox()}])
              }, _toDisplayString(_ctx.subHeading), 3),
              ( !_ctx.isEnableParticipantList|| !_ctx.isGroupsAvailable && _ctx.participantGroups.length <= 0 )
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("Program.ListOfParticipantGroups.NoPartcipantGroupExist")), 1),
                    _createElementVNode("i", {
                      class: "margin-left-5 fa fa-question-circle information-icon",
                      onMouseover: _cache[6] || (_cache[6] = ($event: any) => (_ctx.getInformationString(true, 'Not Available'))),
                      onMouseleave: _cache[7] || (_cache[7] = ($event: any) => (_ctx.getInformationString(false,'Not Available')))
                    }, null, 32),
                    _withDirectives(_createElementVNode("div", { class: "dvInfoForNotAvailableGroups" }, _toDisplayString(_ctx.$t("Program.ListOfParticipantGroups.NotAvailableInfoMsg")), 513), [
                      [_vShow, _ctx.showNotAvailableInformation]
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.participantGroups, (group, index) => {
              return _withDirectives((_openBlock(), _createElementBlock("div", {
                key: group.Id,
                index: index
              }, [
                _createElementVNode("label", null, [
                  _withDirectives(_createElementVNode("input", {
                    id: group.Id,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedParticipantGroups) = $event)),
                    type: "checkbox",
                    value: group,
                    onClick: ($event: any) => (_ctx.selectedParticipantGroupList(group,index,$event))
                  }, null, 8, _hoisted_17), [
                    [_vModelCheckbox, _ctx.selectedParticipantGroups]
                  ]),
                  _createElementVNode("span", _hoisted_18, _toDisplayString(group.Name), 1),
                  (group.ParticipantCounts <= 1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_19, " (" + _toDisplayString(group.ParticipantCounts) + " " + _toDisplayString(_ctx.$t("Program.ListOfParticipantGroups.Participant")) + ")", 1))
                    : _createCommentVNode("", true),
                  (group.ParticipantCounts > 1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_20, " (" + _toDisplayString(group.ParticipantCounts) + " " + _toDisplayString(_ctx.$t("Program.ListOfParticipantGroups.Participants")) + ")", 1))
                    : _createCommentVNode("", true)
                ])
              ], 8, _hoisted_16)), [
                [_vShow, _ctx.isGroupsAvailable && _ctx.isEnableParticipantList]
              ])
            }), 128))
          ]),
          (_ctx.ShowGroupVisibilityCheckBox())
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _createElementVNode("label", {
                  class: "",
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.SetGroupVisibilityCheckboxValue()))
                }, [
                  _withDirectives(_createElementVNode("input", {
                    ref: "chkGroupVisibility",
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localDisplayGroupInfoInApp) = $event)),
                    disabled: _ctx.selectedParticipantGroups.length === 0,
                    type: "checkbox",
                    class: "checkbox-alignment"
                  }, null, 8, _hoisted_22), [
                    [_vModelCheckbox, _ctx.localDisplayGroupInfoInApp]
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("GroupVisibility.DisplayGroupInformationInApp")), 1)
                ]),
                _createElementVNode("i", {
                  class: "margin-left-5 fa fa-info-circle information-icon",
                  onMouseover: _cache[11] || (_cache[11] = ($event: any) => (_ctx.ShowHideGroupVisibilityTooltip(true))),
                  onMouseleave: _cache[12] || (_cache[12] = ($event: any) => (_ctx.ShowHideGroupVisibilityTooltip(false)))
                }, null, 32),
                _withDirectives(_createElementVNode("div", { class: "group-visibility-info" }, _toDisplayString(_ctx.$t("GroupVisibility.tooltip")), 513), [
                  [_vShow, _ctx.isGroupVisibilityTooltipVisible]
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
  ]))
}