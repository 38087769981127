
import { ProductPrice } from '@/entities/ProductPrice';
import { Component, Vue, Prop, toNative } from 'vue-facing-decorator';
import { PricingCalculatorTypes } from '@/entities/PricingCalculatorType';
import VodTypeEnum from '@/enums/VodTypeEnums';
import { Store, Root, t } from '@/main';
@Component
class MeetappPricingDetails extends Vue {
  @Prop() private productPricingInformation?: ProductPrice;
  @Prop() private showPricingPopup?: boolean;
  @Prop() private pricingPopupType?: number;
  private localShowModalPopup = true;
  private pricingInformation: ProductPrice = ProductPrice.createEmpty();
  private virtualMeetingPricingLine1: string = t('VirtualMeetings.MeetAppLivePricingLine1').toString();
  private virtualMeetingPricingLine2: string = t('VirtualMeetings.MeetAppLivePricingLine2').toString();
  private virtualMeetingNoteLine: string = t('VirtualMeetings.MeetAppLivePricingNoteLine').toString();

  private virtualMeetingPricingCurrentPrice = '';

  private virtualMeetingPricingExampleLine1 = '';

  private virtualMeetingPricingExampleLine2 = '';
  private numberofParticipants = 100;
  private numberofMintutes = 60;
  private totalAmount = '';
  private isWarningVisible = false;
  private pricingCalculatorType: any = null;
  private showInputFields = true;

  private created() {
    // setting values in drop down while creating a dialogue item under survey
    this.pricingCalculatorType = new PricingCalculatorTypes(
      Root,
      Store,
      this.$t,
    );
  }
  private mounted() {
    this.localShowModalPopup = this.showPricingPopup!;
    this.pricingInformation = this.productPricingInformation!;

    this.virtualMeetingPricingCurrentPrice = this.$t('VirtualMeetings.MeetAppLivePricingCurrentPrice', {UnitValue : this.pricingInformation.ProductPriceAmount, UnitText: this.pricingInformation.UnitText}).toString();

    this.virtualMeetingPricingExampleLine1 = this.$t('VirtualMeetings.MeetAppLivePricingExampleLine1', {amount: this.CalculateConsumptionBasedPricing(this.pricingInformation.ProductPriceAmount, this.pricingInformation.UnitValue, 100, 60)}).toString();

    this.virtualMeetingPricingExampleLine2 = this.$t('VirtualMeetings.MeetAppLivePricingExampleLine2', {amount: this.CalculateConsumptionBasedPricing(this.pricingInformation.ProductPriceAmount, this.pricingInformation.UnitValue, 250, 120)}).toString();
    this.GetTotalPrice();
    // if pricing popup open from meetApp live recording
    if (this.pricingPopupType === VodTypeEnum.MeetAppLiveRecording) {

      this.virtualMeetingPricingCurrentPrice = this.$t('VirtualMeetings.MeetAppLiveRecordingPricingCurrentPrice', {UnitValue : this.pricingInformation.ProductPriceAmount, UnitText: this.pricingInformation.UnitText}).toString();
      this.virtualMeetingPricingExampleLine1 = '';
      this.virtualMeetingPricingExampleLine2 = '';
      // hide extra tags/ section from template side
      this.showInputFields = false;
    }
  }

  private CalculateConsumptionBasedPricing(productPrice: number, unitValue: number, participants: number, minutes: number): number {
    const amount = ((participants * minutes) / unitValue) * productPrice;
    return amount;
  }
  private GetTotalPrice() {
    const noofParticipants = Number(this.numberofParticipants);
    this.isWarningVisible = noofParticipants > 5000 ? true : false;
    const noofMinutes = Number(this.numberofMintutes);

    const totalPrice = this.CalculateConsumptionBasedPricing(this.pricingInformation.ProductPriceAmount, this.pricingInformation.UnitValue, noofParticipants, noofMinutes);
    this.totalAmount = (totalPrice).toFixed(1);
  }
  private Cancel() {
    Root.emit('show-meetapp-live-pricing-popup', false);
  }
}
export default toNative(MeetappPricingDetails);
