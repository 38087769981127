import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e209d134"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-listing" }
const _hoisted_2 = {
  key: 0,
  class: "margin-top-15"
}
const _hoisted_3 = { class: "edit-section" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "edit-link" }
const _hoisted_6 = {
  class: "hyperLink",
  href: "javascript:void(0)"
}
const _hoisted_7 = { class: "track-details" }
const _hoisted_8 = { class: "item-position-from-image track-input" }
const _hoisted_9 = {
  class: "label-track-name-color",
  for: ""
}
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = {
  key: 0,
  class: "error-vuelidate"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = {
  class: "label-track-name-color",
  for: ""
}
const _hoisted_14 = { class: "color-container" }
const _hoisted_15 = { class: "submit-position margin-top-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_colorpicker = _resolveComponent("colorpicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.localTrackItem && !_ctx.showImageProcess)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.isImage)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "img-select round-icon",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickOnImage(true)))
                }, [
                  _createElementVNode("img", {
                    class: "colorPickerSquare",
                    src: _ctx.GetImageUrl()
                  }, null, 8, _hoisted_4),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.$t("Button.Edit")), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("Track.Name")), 1),
                _withDirectives(_createElementVNode("input", {
                  id: "inputTrackItemName",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$.localTrackItem.Name.$model) = $event)),
                  type: "text",
                  class: _normalizeClass(["form-control valign width90", _ctx.status(_ctx.v$.localTrackItem.Name)]),
                  placeholder: _ctx.getPlaceholder(),
                  maxlength: "70"
                }, null, 10, _hoisted_10), [
                  [
                    _vModelText,
                    _ctx.v$.localTrackItem.Name.$model,
                    void 0,
                    { trim: true }
                  ]
                ]),
                (_ctx.v$.localTrackItem.Name.$error)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.$t("Validation.NameRequired")), 1))
                  : _createCommentVNode("", true)
              ]),
              (!_ctx.isImage)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("Track.Color")), 1),
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(_component_colorpicker, {
                        id: "Foregroundcolor",
                        class: "colorpicker-width",
                        color: _ctx.localTrackItem.TrackColor,
                        onSetForegroundcolorFromPicker: _ctx.SetForegroundcolor
                      }, null, 8, ["color", "onSetForegroundcolorFromPicker"]),
                      _createElementVNode("div", {
                        style: _normalizeStyle(_ctx.GetColor()),
                        class: "colorPicker"
                      }, "    ", 4)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-size-default",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.Submit(_ctx.v$)))
              }, _toDisplayString(_ctx.$t("SaveButton")), 1),
              _createElementVNode("button", {
                class: "btn btn-secondary btn-size-default margin-left-15",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.Cancel(_ctx.v$)))
              }, _toDisplayString(_ctx.$t("CancelButton")), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}