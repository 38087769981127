
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import { VonageSessionData } from '@/entities/VonageSessionData';
import loading from '@/components/Common/loading.vue';
import { FetchData } from '@/main';
import ApiContentType from '@/enums/ApiContentType';
@Component({
  components: {
    loading,
  },
})
class VirtualMeetingComprehensiveDetails extends Vue {
  public loaderBorderColor = '';
  @Prop() private vonageAnalytics?: VonageSessionData;
  @Prop() private selectedProgramId?: number;
  @Prop() private moduleType?: string;
  private vonageAnalyticslocal: VonageSessionData = VonageSessionData.createEmpty();
  private showLoading = false;
  private excelIcon: string = require('@/assets/Images/Excel.jpeg');
  private fileDownload = require('js-file-download');

  private created() {
    this.vonageAnalyticslocal = this.vonageAnalytics!;
  }

  // Export Vonage Session Data for a particular Program
  private ExportSingleVonageAnalyticsToExcel() {
    this.showLoading = true;
    FetchData('/FileDownload/ExportVonageAnalyticsByEntityIdAndEntityType', JSON.stringify({
      // Add program Id
      entityId: this.selectedProgramId,
      entityType:this.moduleType,
    }), ApiContentType.Excel)
      .then((response) => {
        this.fileDownload(response, 'VentlaLiveDetails.xlsx');
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }

}
export default toNative(VirtualMeetingComprehensiveDetails);
