import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ec732b20"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top-margin-in-fields" }
const _hoisted_2 = {
  key: 0,
  class: "inline-flex100 align-items-center"
}
const _hoisted_3 = { class: "main-title-width" }
const _hoisted_4 = { class: "main-heading primary-heading" }
const _hoisted_5 = { class: "row top-margin-in-fields" }
const _hoisted_6 = { class: "col-xl-12" }
const _hoisted_7 = {
  key: 1,
  class: "row"
}
const _hoisted_8 = { class: "col-xl-12 vonageAnalytics" }
const _hoisted_9 = { class: "" }
const _hoisted_10 = { class: "" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-xl-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VirtualMeetingHighChart = _resolveComponent("VirtualMeetingHighChart")!
  const _component_VirtualMeetingComprehensiveDetails = _resolveComponent("VirtualMeetingComprehensiveDetails")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.showCancelButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("button", {
              class: "btn btn-secondary btn-size-default",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Cancel()))
            }, _toDisplayString(_ctx.$t("Button.Back")), 1)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('VirtualMeetings.HighChart.Title')), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_ctx.vonageAnalyticslocal != null)
          ? (_openBlock(), _createBlock(_component_VirtualMeetingHighChart, {
              key: 0,
              "series-data": _ctx.vonageAnalyticslocal.VonageSessionAttendeeData,
              "start-point": _ctx.vonageAnalyticslocal.VonageSessionStartTime
            }, null, 8, ["series-data", "start-point"]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.showCancelButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("VirtualMeetings.TotalMetingMinutes")) + " : ", 1),
              _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.totalMeetingMinutes) + " " + _toDisplayString(_ctx.$t("mins")), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("VirtualMeetings.AverageSeesionTime")) + " : ", 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.averageSessionTime) + " " + _toDisplayString(_ctx.$t("mins")), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("VirtualMeetings.Moderator")) + " : ", 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.moderators), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        (_ctx.vonageAnalyticslocal != null)
          ? (_openBlock(), _createBlock(_component_VirtualMeetingComprehensiveDetails, {
              key: 0,
              "vonage-analytics": _ctx.vonageAnalyticslocal,
              "selected-program-id": _ctx.selectedProgramId,
              "module-type": _ctx.moduleType
            }, null, 8, ["vonage-analytics", "selected-program-id", "module-type"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}