import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createTextVNode as _createTextVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, TransitionGroup as _TransitionGroup, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58976f88"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "sessionQuestionEditMode",
  class: "default-page-height editPane scroll-top"
}
const _hoisted_2 = { class: "right-container-margin" }
const _hoisted_3 = { class: "inline-flex100 align-items-center" }
const _hoisted_4 = { class: "main-title-width" }
const _hoisted_5 = {
  key: 0,
  class: "main-heading primary-heading"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "main-heading primary-heading"
}
const _hoisted_8 = {
  key: 1,
  class: "main-heading primary-heading"
}
const _hoisted_9 = { class: "inline-flex" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "padding-top-20" }
const _hoisted_12 = {
  key: 0,
  class: "alert alert-success margin-top-10"
}
const _hoisted_13 = { class: "top-margin-in-fields" }
const _hoisted_14 = { class: "inline-flex100" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = ["disabled"]
const _hoisted_18 = ["id", "value"]
const _hoisted_19 = ["src"]
const _hoisted_20 = ["hidden"]
const _hoisted_21 = { class: "margin-left-3" }
const _hoisted_22 = { class: "top-margin-in-fields" }
const _hoisted_23 = { class: "font-size-16" }
const _hoisted_24 = { class: "color-red" }
const _hoisted_25 = ["placeholder"]
const _hoisted_26 = { key: 0 }
const _hoisted_27 = { class: "top-margin-in-fields" }
const _hoisted_28 = ["src"]
const _hoisted_29 = ["onUpdate:modelValue", "onInput"]
const _hoisted_30 = ["onClick", "onTouchstart"]
const _hoisted_31 = { class: "top-margin-in-fields" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_SessionQuestionsProgramListing = _resolveComponent("SessionQuestionsProgramListing")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "body-heading": _ctx.modalPopupContent,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "body-heading", "show-close-button"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("button", {
            class: "btn btn-secondary btn-size-default",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Cancel(_ctx.v$)))
          }, _toDisplayString(_ctx.$t("CancelButton")), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.isMobileView)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t("SessionQuestions.Question")), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_ctx.localSessionQuestion.id>0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t("SessionQuestions.EditSessionQuestion")), 1))
                  : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t("SessionQuestions.AddSessionQuestion")), 1))
              ]))
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("button", {
            class: "btn btn-primary btn-size-default",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.Submit(_ctx.v$)))
          }, _toDisplayString(_ctx.$t("SaveButton")), 1),
          (_ctx.localSessionQuestion.id>0)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "btn btn-delete",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.Delete()))
              }, [
                _createElementVNode("img", { src: _ctx.deleteIcon }, null, 8, _hoisted_10)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        (_ctx.visibleValidationSummary)
          ? (_openBlock(), _createBlock(_component_ValidationSummary, {
              key: 0,
              "error-fields": _ctx.validationErrorFields,
              onCloseClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.CloseValidationSummary($event)))
            }, null, 8, ["error-fields"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.messages.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message) => {
              return (_openBlock(), _createElementBlock("span", { key: message }, _toDisplayString(message), 1))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          (_ctx.isMobileView)
            ? (_openBlock(), _createElementBlock("label", _hoisted_15, _toDisplayString(_ctx.$t("SessionQuestions.Type")) + ":", 1))
            : (_openBlock(), _createElementBlock("label", _hoisted_16, _toDisplayString(_ctx.$t("SessionQuestions.QuestionType")) + ":", 1)),
          _withDirectives(_createElementVNode("select", {
            id: "selQuestiontype",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localSessionQuestion.ProgramQuestionType) = $event)),
            class: "form-control question-type-width selectBox",
            disabled: _ctx.localSessionQuestion.id>0,
            onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.OnQuestiontypeChange($event)))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.programQuestionTypes, (questiontype) => {
              return (_openBlock(), _createElementBlock("option", {
                id: 'sessionQuestionsOption'+questiontype.Id,
                key: questiontype.Id,
                value: questiontype.Id
              }, _toDisplayString(_ctx.$t(questiontype.QuestionTypeName)), 9, _hoisted_18))
            }), 128))
          ], 40, _hoisted_17), [
            [_vModelSelect, _ctx.localSessionQuestion.ProgramQuestionType]
          ]),
          (_ctx.localSessionQuestion.id>0)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "export-excel action-button margin-left-20",
                onTouchstart: _cache[7] || (_cache[7] = ($event: any) => (_ctx.ExportSingleProgramQuestionResultToExcel())),
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.ExportSingleProgramQuestionResultToExcel()))
              }, [
                _createElementVNode("img", {
                  src: _ctx.excelIcon,
                  class: "excel-icon-size"
                }, null, 8, _hoisted_19),
                _createElementVNode("a", {
                  class: "hyperLink description",
                  hidden: _ctx.isMobileView
                }, [
                  _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t("ExportToExcel")), 1)
                ], 8, _hoisted_20)
              ], 32))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("label", _hoisted_23, [
            _createTextVNode(_toDisplayString(_ctx.$t("SessionQuestions.Question")) + " ", 1),
            _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t("Asteric")), 1)
          ]),
          _withDirectives(_createElementVNode("input", {
            id: "inputSessionQuestions",
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.v$.localSessionQuestion.questionTitle.$model) = $event)),
            class: _normalizeClass([_ctx.status(_ctx.v$.localSessionQuestion.questionTitle), "form-control textarea-size"]),
            type: "text",
            placeholder: _ctx.$t('SessionQuestions.QuestionPlaceholder'),
            maxlength: "100"
          }, null, 10, _hoisted_25), [
            [_vModelText, _ctx.v$.localSessionQuestion.questionTitle.$model]
          ])
        ]),
        (_ctx.isPollingTypeQuestion)
          ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t("SessionQuestions.SelectedOptions")), 1),
                _createElementVNode("a", {
                  class: "hyperLink margin-left-20 description",
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.AddNewOption()))
                }, _toDisplayString(_ctx.$t("SessionQuestions.CreateOption")), 1)
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_draggable, {
                  list: _ctx.selectedOptions,
                  tag: "ul",
                  class: "list-group",
                  move: _ctx.checkMove,
                  onStart: _ctx.draggingStart,
                  onEnd: _ctx.draggingEnd
                }, {
                  default: _withCtx(() => [
                    _createVNode(_TransitionGroup, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedOptions, (item) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: item.id,
                            class: "margin-top-10"
                          }, [
                            (_openBlock(), _createElementBlock("div", {
                              key: item.id,
                              class: _normalizeClass([{ mobileViewOptionsWidth: _ctx.isMobileView, width100: !_ctx.isMobileView }, "drag-item inline-flex"])
                            }, [
                              _createElementVNode("img", {
                                class: "margin-right-10",
                                src: _ctx.dragDropIcon
                              }, null, 8, _hoisted_28),
                              _withDirectives(_createElementVNode("input", {
                                "onUpdate:modelValue": ($event: any) => ((item.optionName) = $event),
                                style: _normalizeStyle(_ctx.GetTextboxStyling(item)),
                                type: "text",
                                class: "form-control",
                                maxlength: "100",
                                onInput: ($event: any) => (_ctx.CheckIfEmptyOption(item))
                              }, null, 44, _hoisted_29), [
                                [_vModelText, item.optionName]
                              ]),
                              _createElementVNode("i", {
                                class: "fa fa-times icon-position",
                                "aria-hidden": "true",
                                onClick: ($event: any) => (_ctx.RemoveOption(item)),
                                onTouchstart: ($event: any) => (_ctx.RemoveOption(item))
                              }, null, 40, _hoisted_30)
                            ], 2))
                          ]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["list", "move", "onStart", "onEnd"])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_31, [
          _createVNode(_component_SessionQuestionsProgramListing, {
            "program-items": _ctx.programItems,
            "selected-program-ids": _ctx.selectedProgramIds,
            "select-all-items": _ctx.selectAllProgramItems,
            "selected-item": _ctx.localSessionQuestion,
            "is-global-program-question": _ctx.localSessionQuestion.globalProgramQuestion,
            onProgramSessionQuestionSetSelectedProgramItems: _cache[11] || (_cache[11] = ($event: any) => (_ctx.SetSelectedProgramItems($event))),
            onProgramSessionquestionItemGlobalProgramQuestion: _cache[12] || (_cache[12] = ($event: any) => (_ctx.SetSessionQuestionIsGlobalProgramQuestion($event)))
          }, null, 8, ["program-items", "selected-program-ids", "select-all-items", "selected-item", "is-global-program-question"])
        ])
      ])
    ])
  ]))
}