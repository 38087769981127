
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import { ProgramItem } from '@/entities/Program/ProgramItem';
import loading from '@/components/Common/loading.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import ModuleEnum from '@/enums/ModuleEnum';
import SubModuleEnum from '@/enums/SubModuleEnum';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { ProgramSignalRModel } from '@/signalRRequest/Program/ProgramSignalRModel';
import { ProgramTrackItem } from '@/entities/Program/ProgramTrackItem';
import { StoreHelper } from '@/store/StoreHelper';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import { VodDetail } from '@/entities/VodDetail';
import VirtualMeetingEnums from '@/enums/VirtualMeetingEnums';
import { ParticipantGroups } from '@/entities/ParticipantGroups';
import { AppColorSettingsInfo } from '@/entities/Application/AppColorSettingsInfo';
import { DateTimeOptions } from 'vue-i18n';
import VodModuleTypeEnum from '@/enums/VodModuleTypeEnum';
import { FetchData, Root, Store } from '@/main';
import VodMuxVideoData from '@/entities/Emits/Common/VodMuxVideoData';
import CommonStyle from '@/helper/CommonStyle';
@Component({
  components: {
    loading,
    ModalPopup,
  },
})
class ProgramListView extends Vue {
  @Prop() private programItems?: ProgramItem[];
  @Prop() private selectAllItems?: boolean;
  @Prop() private applicationborderColor?: string;
  @Prop() private editingProgramItem?: ProgramItem;
  @Prop() private trackItems?: ProgramTrackItem[];
  @Prop() private participantGroups?: ParticipantGroups[];
  private selectedProgramItem: ProgramItem = ProgramItem.createEmpty();
  private programDivBackgroundColor = '#f9f9f9';
  private whiteColor = '#FFFFFF';
  private ellipsesText = '...';
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private temporarySelectedItems: ProgramItem[] = [];
  private emptySelectedItems = true;
  private isMobileView = false;
  private selectedCheckboxCount = 0;
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupShowCancelButton = true;
  private modalPopupCancelButtonText = '';
  private modalPopupConfirmButtonText = '';
  private modalPopupContent: string = this.getMoveToAnotherProgramMessage();
  private showSectionModalPopup = '';
  private selectedProgramItemValue: ProgramItem[] = [];
  private oldProgramItemValue: ProgramItem[] = [];
  private newItemCreated = false;
  private selectedItemId = 0;
  private programDetailInfo: any = [];
  private isVideoUploadinProgress = false;
  private AssetReady = false;
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private dateOptions: DateTimeOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC',
  };
  // constants
  private readonly EditBoth = 'both';
  private readonly EditIcon = 'icon';
  private readonly EditFeatured = 'featured';
  private readonly Notification = 'Notification';
  private readonly NotificationSessionQuestionUpdated = 'NotificationSessionQuestionUpdated';
  private readonly NotificationAddInformationUpdated = 'NotificationAddInformationUpdated';
  private readonly NotificationMultimediaUpdated = 'NotificationMultimediaUpdated';
  private readonly NotificationMultimediaDeleteUpdated = 'NotificationMultimediaDeleteUpdated';
  private readonly Notification360ImagesUpdated = 'Notification360ImagesUpdated';
  private readonly VodVideoUploadInProgress = 'VodVideoUploadInProgress';
  private readonly Notification360ImagesDeleteUpdated = 'Notification360ImagesDeleteUpdated';
  private readonly NotificationEvaluationQuestionUpdated = 'NotificationEvaluationQuestionUpdated';
  private readonly NotificationUpdateInformationListForProgramLinking = 'NotificationUpdateInformationListForProgramLinking';
  private readonly NotificationUpdateInformationListForProgramLinkingBulk = 'NotificationUpdateInformationListForProgramLinkingBulk';
  private readonly NotificationVideoUploadedSuccessfully = 'NotificationVideoUploadedSuccessfully';
  private readonly NotificationVideoDeletedSuccessfully = 'NotificationVideoDeletedSuccessfully';
  private readonly WarningMessage = 'WarningMessage';
  private readonly SessionQuestions = 'SessionQuestions';
  private readonly LinkedInformation = 'LinkedInformation';
  private readonly Multimedia = 'Multimedia';
  private readonly MultimediaDelete = 'Multimedia-Delete';
  private readonly Image360 = 'Image360';
  private readonly Image360Delete = 'Image360-Delete';
  private readonly EvaluationQuestions = 'EvaluationQuestions';
  private dateFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat();
  private getMoveToAnotherProgramMessage(): string {
    return this.$t('ChangeScreenMessage').toString();
  }

  private GetDefaultColor() {
    // get by default color for track color
    const appDefaultColorsInfo: AppColorSettingsInfo = this.storeHelper.GetApplicationColorSettingsFromStore();
    return appDefaultColorsInfo.topNavBackgroundColor;
  }
  private mounted() {
    const browsersettings: BrowserSettingsInfo = this.storeHelper.GetBrowserSettingsFromStore();
    this.dateFormat = new Intl.DateTimeFormat(browsersettings.locale, this.dateOptions);
    Root.on('clearCheckboxes', () => {
      this.ClearAllCheckBoxes();
      this.resetProgramItemsBackgroundColor();
    });
    Root.on('createEmptyItem', (newItem: ProgramItem) => {
      this.ClearAllCheckBoxes();
      this.resetProgramItemsBackgroundColor();
      this.selectedItems = [newItem];
    });
    // Preselect Item from Program List when any item is Created or updated
    Root.on('program-item-select-operation', (newItem: ProgramItem) => {
      this.ClearAllCheckBoxes();
      this.newItemCreated = true;
      this.selectItem(newItem);
    });
    // emitted property from program when new program item added
    Root.on('scroll-to-newly-added-item', (index: number) => {
      this.RedirectToProgramItem(index);
    });
    Root.on('update-information-linking-in-programs', (notification: SignalRModelRequest) => {

      if ((notification.Module === ModuleEnum.Information || notification.Module === ModuleEnum.InformationFolder) && (notification.SubModule === SubModuleEnum.SingleDelete || SubModuleEnum.SingleCreate || SubModuleEnum.Delete || SubModuleEnum.EditIconsAndImages || SubModuleEnum.LinkProgram || SubModuleEnum.AssignToFolder)) {
        this.UpdateInformationListForProgramLinking(notification);
      }
    });
    Root.on(
      'selected-program-item-notification-completed',
      (notification: SignalRModelRequest) => {
        const signalR: ProgramSignalRModel = new ProgramSignalRModel(Root, Store, this.$t);
        signalR.ProgramSignalRModelResponseReceived(notification);
        if (notification.IsSuccess) {
          this.PerformOperationOnList(notification);
        }
      },
    );
    Root.on(
      'selected-program-video-upload-progress',
      (notification: SignalRModelRequest) => {
        this.PerformOperationOnList(notification);
      },
    );
    Root.on(
      'update-tracks-in-listing',
      () => {
        this.UpdateTracksInListing();
      },
    );
    Root.on(
      'update-tags-in-listing', () => {
        this.UpdateTagsInListing();
      },
    );
    Root.on(
      'program-item-bulk-datetime-operation',
      (notification: SignalRModelRequest) => {
        this.UpdateDateTimeInListing(notification);
      },
    );
    Root.on(
      'update-information-in-listing',
      () => {
        this.UpdateLinkedInformation();
      },
    );

    Root.on('reload-program-detail', () => {
      const selectedProgramItem: ProgramItem = this.selectedItems[0];
      this.selectedItems = [];
      this.selectedItems.push(selectedProgramItem);
      this.selectedCheckboxCount = 1;
      this.FillProgramDetails(selectedProgramItem);
    });
    // Check for mobile view
    if (window.innerWidth <= 480) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    Root.on(
      'program-item-bulk-delete-operation',
      (notification: SignalRModelRequest) => {
        notification.SelectedIds.forEach((element) => {
          this.programItemDeletedOperationWithNotification(element, notification);
        });
        Root.emit(
          'update-attached-programids-in-session-questions-with-notification-new',
        );
        Root.emit(
          'update-attached-programids-in-evaluation-questions-with-notification-new',
        );
      },
    );
    Root.on(
      'program-item-bulk-imageUrl-operation',
      (notification: SignalRModelRequest) => {
        this.programItemIconChangeOperation(notification);
      },
    );
    Root.on(
      'program-item-video-upload-inprogress',
      (isinProgress: boolean) => {
        this.isVideoUploadinProgress = isinProgress;
      },
    );
    Root.on('disable-program-items', (programIds: number[]) => {
      programIds.forEach((element) => {
        this.programItems!.find((x) => x.Id === element)!.DisableItem = true;
      });
    });
    Root.on('enable-program-items', (programIds: number[]) => {
      programIds.forEach((element) => {
        this.programItems!.find((x) => x.Id === element)!.DisableItem = false;
      });
    });

    Root.on('createEmptyProgramItem', (newItem: ProgramItem) => {
      this.ClearAllCheckBoxes();
      this.selectedItems = [newItem];
      this.resetProgramItemsBackgroundColor();
    });
  }

  private UpdateTagsInListing() {
    Root.emit('reload-programs-data');
  }

  private UpdateDateTimeInListing(notification: SignalRModelRequest) {
    const minutesObject: any = JSON.parse(notification.JSONData);
    const minutes: number = minutesObject.minutes;
    const self = this;
    notification.SelectedIds.forEach(function(item) {
      const objIndex = self.programItems!.findIndex(
        (obj: ProgramItem) => obj.Id === item,
      );
      if (objIndex > -1) {
        const pItem: ProgramItem = self.programItems![objIndex];
        pItem.StartDate.setMinutes(pItem.StartDate.getMinutes() + Number(minutes));
        pItem.EndDate.setMinutes(pItem.EndDate.getMinutes() + Number(minutes));
        if(self.programItems !== null && self.programItems !== undefined) {
          self.programItems[objIndex] = pItem;
        }
      }
    });
    this.$emit('update-program-sorting', true);
  }

  private UpdateLinkedInformation() {
    Root.emit('reload-programs-data');
  }

  private programItemIconChangeOperation(notification: SignalRModelRequest) {
    const programObject: any = JSON.parse(notification.JSONData);
    const self = this;
    notification.SelectedIds.forEach(function(item) {
      const objIndex = self.programItems!.findIndex(
        (obj: ProgramItem) => obj.Id === item,
      );
      if (objIndex > -1) {
        const pItem: ProgramItem = self.programItems![objIndex];
        if (programObject.selectedBatch === self.EditBoth) {
          pItem.ImageUrl = programObject.croppedimageIconUrl;
          pItem.MainMediaFullUrl = programObject.imageIconUrl;
          pItem.FeaturedImageUrl = programObject.croppedFeaturedImageIconUrl;
          pItem.FeaturedOrginalImageUrl = programObject.featuredImageUrl;
          pItem.TopPosition = programObject.topPosition;
          pItem.LeftPosition = programObject.leftPosition;
          pItem.ShowFeatureImageBelowTitle = programObject.showFeaturedImageBelowTitle;
          pItem.FeaturedImageWidth = programObject.featuredImageWidth;
          pItem.FeaturedImageHeight = programObject.FeaturedImageHeight;
        } else if (programObject.selectedBatch === self.EditIcon) {
          pItem.ImageUrl = programObject.croppedimageIconUrl;
          pItem.MainMediaFullUrl = programObject.imageIconUrl;
        } else if (programObject.selectedBatch === self.EditFeatured) {
          pItem.FeaturedImageUrl = programObject.croppedFeaturedImageIconUrl;
          pItem.FeaturedOrginalImageUrl = programObject.featuredImageUrl;
          pItem.TopPosition = programObject.topPosition;
          pItem.LeftPosition = programObject.leftPosition;
          pItem.ShowFeatureImageBelowTitle = programObject.showFeaturedImageBelowTitle;
          pItem.FeaturedImageWidth = programObject.featuredImageWidth;
          pItem.FeaturedImageHeight = programObject.FeaturedImageHeight;
        }
        if(self.programItems !== null && self.programItems !== undefined) {
          self.programItems[objIndex] = pItem;
        }
      }
    });
  }
  private UpdateInformationListForProgramLinking(notification: SignalRModelRequest) {
    if (
      this.selectedItems.length === 1
    ) {
      if (this.selectedItems[0].Id > 0) {
        this.modalPopupVisible = true;
        this.showCloseButton = false;
        this.showSectionModalPopup = this.NotificationUpdateInformationListForProgramLinking;
        this.modalPopupShowCancelButton = false;
        this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
        this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
        this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
          username: notification.UserName,
        }).toString();
      }
    } else if ( this.selectedItems.length > 1) {
      this.modalPopupVisible = true;
      this.showCloseButton = false;
      this.showSectionModalPopup = this.NotificationUpdateInformationListForProgramLinkingBulk;
      this.modalPopupShowCancelButton = false;
      this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
      this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
      this.modalPopupContent = this.$t('Message.ItemsUpdatedBy', {
        username: notification.UserName,
      }).toString();
    } else {
      Root.emit('reload-programs-data');
    }
  }
  private UpdateTracksInListing() {
    Root.emit('reload-programs-data');
  }
  private PerformOperationOnList(notification: SignalRModelRequest) {
    if (
      notification.Module === ModuleEnum.Program &&
      notification.SubModule === SubModuleEnum.SingleDelete
    ) {
      if (notification.SelectedIds.length > 0) {
        if (
          this.selectedItems.length === 1 &&
        this.selectedItems[0].Id === notification.SelectedIds[0]
        ) {
          this.CheckIfDeletedItemIsOpenInEditMode(notification);
        } else {
          this.programItemDeletedOperationWithNotification(notification.SelectedIds[0], notification);
        }
      }
    } else if (notification.Module === ModuleEnum.Program &&
      notification.SubModule === SubModuleEnum.SingleDetailEdit) {
      const programQuestionDetails = JSON.parse(notification.JSONData);
      if (programQuestionDetails.programDetailType === this.SessionQuestions) {
        this.UpdateSessionQuestionForProgramDetails(notification, programQuestionDetails);
      } else if (programQuestionDetails.programDetailType === this.LinkedInformation) {
        this.UpdateLinkedInformationForProgramDetails(notification, programQuestionDetails);
      } else if (programQuestionDetails.programDetailType === this.Multimedia) {
        this.UpdateMultimediaForProgramDetails(notification, programQuestionDetails);
      } else if (programQuestionDetails.programDetailType === this.MultimediaDelete) {
        this.DeleteMultimediaForProgramDetails(notification, programQuestionDetails);
      } else if (programQuestionDetails.programDetailType === this.Image360) {
        this.UpdateImage360ForProgramDetails(notification, programQuestionDetails);
      } else if (programQuestionDetails.programDetailType === this.Image360Delete) {
        this.DeleteImage360ForProgramDetails(notification, programQuestionDetails);
      } else if (programQuestionDetails.programDetailType === this.EvaluationQuestions) {
        this.UpdateEvaluationQuestionForProgramDetails(notification, programQuestionDetails);
      }
    } else if (notification.Module === ModuleEnum.Program &&
      notification.SubModule === SubModuleEnum.MuxVodUpdate) {
      if (notification.VodDetail !== null) {
        this.UpdateVodDetailForProgramDetails(notification);
      }
    } else if (
      notification.Module === ModuleEnum.Program &&
      notification.SubModule === SubModuleEnum.MuxVodDelete
    ) {
      if (notification.SelectedIds.length > 0) {
        if (
          this.selectedItems.length === 1 &&
        this.selectedItems[0].Id === notification.SelectedIds[0]
        ) {
          this.CheckIfDeleteVideoIsOpenInEditMode(notification);
        }
      }
    } else {
      Root.emit('program-item-edit-operation-with-notification', notification);
    }
  }
  private CheckIfDeleteVideoIsOpenInEditMode(notification: SignalRModelRequest) {
    const signalR: ProgramSignalRModel = new ProgramSignalRModel(Root, Store, this.$t);
    if (signalR.IsLoggedInUserIsMe(notification)) {
      // Do nothing
    } else {
      this.modalPopupVisible = true;
      this.showCloseButton = false;
      this.showSectionModalPopup = this.NotificationVideoDeletedSuccessfully;
      this.modalPopupShowCancelButton = false;
      this.modalPopupConfirmButtonText = this.$t('Button.Reload').toString();
      this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
      this.modalPopupContent = this.$t('Message.SessionVideoDeletedBy', {
        username: notification.UserName,
      }).toString();
      this.selectedItemId = notification.SelectedIds[0];
    }
  }
  private UpdateVodDetailForProgramDetails(notification: SignalRModelRequest) {
    const signalR: ProgramSignalRModel = new ProgramSignalRModel(
      Root,
      Store,
      this.$t,
    );
    if (
      this.selectedItems.length === 1 &&
        this.selectedItems[0].Id === notification.SelectedIds[0]
    ) {
    // if (!signalR.IsLoggedInUserIsMe(notification)) {
      if (notification.VodDetail !== null && notification.VodDetail !== undefined ) {

        if (notification.VodDetail.VodStatus === 'VideoUploadCreated' || notification.VodDetail.VodStatus === 'VideoUploadAssetCreated') {
          Root.emit('virtual-meeting-meetapp-live-analytics-available', true);
          this.AssetReady = false;
        } else if (notification.VodDetail.VodStatus === 'VideoAssetReady') {
          this.AssetReady = true;
          Root.emit('virtual-meeting-meetapp-live-analytics-available', false);
          if (notification.VodDetail.Id > 0 && notification.VodDetail.VodVideo! == null) {
            this.GetVideoFullDetails(notification.SelectedIds[0]);
          }
        }  else if (notification.VodDetail.VodStatus === 'VideoAssetCreated') {
          Root.emit('entity-item-video-upload-current-status', notification.VodDetail);
        } else {
          Root.emit('entity-item-video-upload-current-status', notification.VodDetail);
        }
        if (notification.VodDetail.VodStatus === 'VideoUploadCreated' || notification.VodDetail.VodStatus === 'VideoAssetReady' || notification.VodDetail.VodStatus === 'VideoAssetDeleted' || notification.VodDetail.VodStatus === 'VideoAssetUpdated') {
          if (signalR.IsLoggedInUserIsMe(notification)) {
            this.modalPopupVisible = true;
            this.showSectionModalPopup = this.NotificationVideoUploadedSuccessfully;
            this.modalPopupShowCancelButton = false;
            this.modalPopupConfirmButtonText = this.$t('Button.Reload').toString();
            this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
              username: notification.UserName,
            }).toString();
          } else {
            Root.emit('entity-item-video-upload-current-status', notification.VodDetail);
          }
        }
      } else {
        Root.emit('entity-item-video-upload-current-status', notification.VodDetail);
      }
    } else {
      Root.emit('entity-item-video-upload-current-status', notification.VodDetail);
    }
  }
  private UpdateSessionQuestionForProgramDetails( notification: SignalRModelRequest,
                                                  programQuestionDetails: any) {
    const signalR: ProgramSignalRModel = new ProgramSignalRModel(
      Root,
      Store,
      this.$t,
    );
    if (signalR.IsLoggedInUserIsMe(notification)) {
      Root.emit(
        'program-sessionQuestion-item-removed-operation',
        programQuestionDetails.id,
      );
      Root.emit(
        'update-session-question-listing',
        true,
      );
    } else {
      if (
        this.selectedItems.length === 1 &&
        this.selectedItems[0].Id === notification.SelectedIds[0]
      ) {
        this.modalPopupVisible = true;
        this.showCloseButton = false;
        this.showSectionModalPopup = this.NotificationSessionQuestionUpdated;
        this.modalPopupShowCancelButton = false;
        this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
        this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
        this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
          username: notification.UserName,
        }).toString();
        this.selectedItemId = programQuestionDetails.id;
      } else {
        Root.emit(
          'program-sessionQuestion-item-removed-operation',
          programQuestionDetails.id,
        );

        Root.emit(
          'program-sessionQuestion-item-removed-with-notification-operation',
          programQuestionDetails.id, notification,
        );
      }
    }
  }


  private GetVideoFullDetails(programId: number) {
    FetchData('/Program/GetFullVodDetailAsyncUpdated', JSON.stringify({
      entityId: programId,
      entityType: VodModuleTypeEnum[VodModuleTypeEnum.Program].toString(),
    }))
      .then((response) => {
        const vodStatus: VodDetail  = response;
        Root.emit('entity-item-video-upload-current-status-api', vodStatus);
      }).catch((error) => {
        window.console.log(error);
      });
  }
  private UpdateEvaluationQuestionForProgramDetails( notification: SignalRModelRequest,
                                                     details: any) {
    const signalR: ProgramSignalRModel = new ProgramSignalRModel(
      Root,
      Store,
      this.$t,
    );
    if (signalR.IsLoggedInUserIsMe(notification)) {
      Root.emit(
        'program-evaluationQuestion-item-removed-operation',
        details.id,
      );
      Root.emit('reload-evaluation-questions-data');
    } else {
      if (
        this.selectedItems.length === 1 &&
        this.selectedItems[0].Id === notification.SelectedIds[0]
      ) {
        this.modalPopupVisible = true;
        this.showCloseButton = false;
        this.showSectionModalPopup = this.NotificationEvaluationQuestionUpdated;
        this.modalPopupShowCancelButton = false;
        this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
        this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
        this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
          username: notification.UserName,
        }).toString();
        this.selectedItemId = details.id;
      } else {
        Root.emit(
          'program-evaluationQuestion-item-removed-operation',
          details.id,
        );
        Root.emit(
          'program-evaluationQuestion-item-removed-with-notification-operation',
          details.id, notification,
        );

      }
    }
  }
  private UpdateLinkedInformationForProgramDetails( notification: SignalRModelRequest,
                                                    programQuestionDetails: any) {
    const signalR: ProgramSignalRModel = new ProgramSignalRModel(
      Root,
      Store,
      this.$t,
    );
    if (signalR.IsLoggedInUserIsMe(notification)) {
      Root.emit(
        'program-customcategory-item-added',
        {programQuestionDetails, showAddInformation: true },
      );
    } else {
      if (
        this.selectedItems.length === 1 &&
        this.selectedItems[0].Id === notification.SelectedIds[0]
      ) {
        this.modalPopupVisible = true;
        this.showCloseButton = false;
        this.showSectionModalPopup = this.NotificationAddInformationUpdated;
        this.modalPopupShowCancelButton = false;
        this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
        this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
        this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
          username: notification.UserName,
        }).toString();
        this.programDetailInfo = programQuestionDetails;
      } else {
        Root.emit(
          'program-customcategory-item-added',
          {programQuestionDetails, showAddInformation: false },
        );
      }
    }
  }
  private UpdateMultimediaForProgramDetails(notification: SignalRModelRequest,
                                            programQuestionDetails: any) {
    const signalR: ProgramSignalRModel = new ProgramSignalRModel(
      Root,
      Store,
      this.$t,
    );
    if (signalR.IsLoggedInUserIsMe(notification)) {
      Root.emit(
        'program-multimedia-item-edit-operation',
        programQuestionDetails.dataItem,
      );
    } else {
      if (
        this.selectedItems.length === 1 &&
        this.selectedItems[0].Id === notification.SelectedIds[0]
      ) {
        this.modalPopupVisible = true;
        this.showCloseButton = false;
        this.showSectionModalPopup = this.NotificationMultimediaUpdated;
        this.modalPopupShowCancelButton = false;
        this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
        this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
        this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
          username: notification.UserName,
        }).toString();
        this.programDetailInfo = programQuestionDetails.dataItem;
      } else {
        Root.emit(
          'program-multimedia-item-edit-operation',
          programQuestionDetails.dataItem,
        );
      }
    }
  }
  private DeleteMultimediaForProgramDetails(notification: SignalRModelRequest,
                                            programQuestionDetails: any) {
    const signalR: ProgramSignalRModel = new ProgramSignalRModel(
      Root,
      Store,
      this.$t,
    );
    if (signalR.IsLoggedInUserIsMe(notification)) {
      Root.emit(
        'program-multimedia-item-delete-operation',
        programQuestionDetails.id,
      );
    } else {
      if (
        this.selectedItems.length === 1 &&
        this.selectedItems[0].Id === notification.SelectedIds[0]
      ) {
        this.modalPopupVisible = true;
        this.showCloseButton = false;
        this.showSectionModalPopup = this.NotificationMultimediaDeleteUpdated;
        this.modalPopupShowCancelButton = false;
        this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
        this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
        this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
          username: notification.UserName,
        }).toString();
        this.selectedItemId = programQuestionDetails.id;
      } else {
        Root.emit(
          'program-multimedia-item-delete-operation',
          programQuestionDetails.id,
        );
      }
    }
  }
  private UpdateImage360ForProgramDetails(notification: SignalRModelRequest,
                                          programQuestionDetails: any) {
    const signalR: ProgramSignalRModel = new ProgramSignalRModel(
      Root,
      Store,
      this.$t,
    );
    if (signalR.IsLoggedInUserIsMe(notification)) {
      Root.emit(
        'program-360image-item-edit-operation',
        programQuestionDetails.dataItem,
      );
    } else {
      if (
        this.selectedItems.length === 1 &&
        this.selectedItems[0].Id === notification.SelectedIds[0]
      ) {
        this.modalPopupVisible = true;
        this.showCloseButton = false;
        this.showSectionModalPopup = this.Notification360ImagesUpdated;
        this.modalPopupShowCancelButton = false;
        this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
        this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
        this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
          username: notification.UserName,
        }).toString();
        this.programDetailInfo = programQuestionDetails.dataItem;
      } else {
        Root.emit(
          'program-360image-item-edit-operation',
          programQuestionDetails.dataItem,
        );
      }
    }
  }
  private DeleteImage360ForProgramDetails(notification: SignalRModelRequest,
                                          programQuestionDetails: any) {
    const signalR: ProgramSignalRModel = new ProgramSignalRModel(
      Root,
      Store,
      this.$t,
    );
    if (signalR.IsLoggedInUserIsMe(notification)) {
      Root.emit(
        'program-image360-item-delete-operation',
        programQuestionDetails.id,
      );
    } else {
      if (
        this.selectedItems.length === 1 &&
        this.selectedItems[0].Id === notification.SelectedIds[0]
      ) {
        this.modalPopupVisible = true;
        this.showCloseButton = false;
        this.showSectionModalPopup = this.Notification360ImagesDeleteUpdated;
        this.modalPopupShowCancelButton = false;
        this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
        this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
        this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
          username: notification.UserName,
        }).toString();
        this.selectedItemId = programQuestionDetails.id;
      } else {
        Root.emit(
          'program-360Image-item-delete-operation',
          programQuestionDetails.id,
        );
      }
    }
  }
  private CheckIfDeletedItemIsOpenInEditMode(notification: SignalRModelRequest) {
    const signalR: ProgramSignalRModel = new ProgramSignalRModel(Root, Store, this.$t);
    if (signalR.IsLoggedInUserIsMe(notification)) {
      this.programItemDeletedOperation(notification.SelectedIds[0]);
    } else {
      this.modalPopupVisible = true;
      this.showCloseButton = false;
      this.showSectionModalPopup = this.Notification;
      this.modalPopupShowCancelButton = false;
      this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
      this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
      this.modalPopupContent = this.$t('Message.ItemDeletedBy', {
        username: notification.UserName,
      }).toString();
      this.selectedItemId = notification.SelectedIds[0];
    }
  }
  private programItemDeletedOperation(programId: number) {
    const objIndex = this.programItems!.findIndex(
      (obj: ProgramItem) => obj.Id === programId,
    );
    if (objIndex > -1) {
      this.programItems!.splice(objIndex, 1);
    }
    Root.emit(
      'update-attached-programids-in-session-questions',
      programId,
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private programItemDeletedOperationWithNotification(programId: number, notification: SignalRModelRequest) {
    const objIndex = this.programItems!.findIndex(
      (obj: ProgramItem) => obj.Id === programId,
    );
    if (objIndex > -1) {
      this.programItems!.splice(objIndex, 1);
    }
    // for performance issue
    // Root.emit(
    //   'update-attached-programids-in-session-questions-with-notification',
    //   programId, notification,
    // );
    // Root.emit(
    //   'update-attached-programids-in-evaluation-questions-with-notification',
    //   programId, notification,
    // );
  }
  private updated() {
    const missingImage = this.missingImage;
    this.programItems!.forEach(function(item) {
      if (item.ImageUrl === '') {
        item.ImageUrl = missingImage;
      }
    });
  }

  private ClearAllCheckBoxes() {
    this.selectedItems = [];
  }
  private drawDate(index: number): boolean {
    if (index === 0) {
      return true;
    } else {
      if (this.programItems) {
        const prevDate = this.dateFormat.format(this.programItems[index - 1].StartDate);
        const currDate = this.dateFormat.format(this.programItems[index].StartDate);
        if (prevDate !== currDate) {
          return true;
        }
      }
    }
    return false;
  }
  private drawTrack(index: number): boolean {
    const pi = this.programItems ? this.programItems[index] : null;
    if (pi) {
      if (index > 0) {
        const piPrev = this.programItems ? this.programItems[index - 1] : null;
        if (piPrev) {
          const pitrack = pi.Track ? pi.Track.Name : '';
          const piPrevTrack = piPrev.Track ? piPrev.Track.Name : '';
          const prevDate = this.dateFormat.format(piPrev.StartDate);
          const currDate = this.dateFormat.format(pi.StartDate);
          if ((pitrack === piPrevTrack) && (currDate === prevDate)) {
            // Continuation of track
            return false;
          }
        }
      }
      if (pi.Track) {
        return true;
      }
    }
    return false;
  }
  private drawFinalTrack(index: number): boolean {
    const pi = this.programItems ? this.programItems[index] : null;
    const length = this.programItems ? this.programItems.length : 0;
    // check if last item in track
    if (index === (length - 1)) {
      return true;
    }
    if (pi) {
      if (index < length) {
        const piNext = this.programItems ? this.programItems[index + 1] : null;
        if (piNext) {
          const piTrack = pi.Track ? pi.Track.Name : '';
          const piNTrack = piNext.Track ? piNext.Track.Name : '';
          if (piTrack !== piNTrack && piNTrack !== '') {
            // return true; // New track starting, avoid duplicated lines.
          }
          if (piTrack !== piNTrack) {
            return true;
          }
        }
      }
    }
    return false;
  }
  private formatDate(pi: ProgramItem): string {
    return this.dateFormat.format(pi.StartDate);
  }
  private formatTime(pi: ProgramItem): string {
    return pi.StartDate.toLocaleTimeString([], {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'UTC',
    });
  }
  private formatTagsTitle(pi: ProgramItem): string {
    let tags = '';
    if (pi.ProgramTags !== undefined && pi.ProgramTags.length > 0) {
      pi.ProgramTags.forEach((item) => {
        tags += item.TagName + ', ';
      });
      tags = tags.slice(0, -2);
    }
    return tags;
  }
  private formatTags(pi: ProgramItem): string {
    let tags = '';
    let charCount = 0;
    let index = 0;
    if (pi.ProgramTags !== undefined && pi.ProgramTags.length > 0) {
      const length = pi.ProgramTags.length;
      // use every instead of foreach to break the loop on return of false
      pi.ProgramTags.every((item) => {
        if (charCount + item.TagName.length < 12) {
          charCount += item.TagName.length;
          tags += item.TagName + ', ';
        } else if (item.TagName.length >= 12 && charCount === 0) {
          tags = item.TagName.substr(0, 8) + this.ellipsesText;
          charCount += tags.length;
        } else {
          if (length === 1 && item.TagName.length > 11) {
            tags = item.TagName.substr(0, 8) + this.ellipsesText;
            charCount += tags.length;
          } else if (tags.indexOf('+') < 0) {
            tags += '+ ' + (length - index);
            return false;
          }
        }
        index++;
        return true;
      });
      index = tags.lastIndexOf(',');
      if (index > 0) {
        tags = tags.slice(0, index) + tags.slice(index + 1);
      }
    }
    return tags;
  }
  private EmitSelectedPrograms(val: ProgramItem[]) {
    this.$emit('program-items-selected', val);
  }

  private selectedItems: ProgramItem[] = [];
  @Watch('selectedItems')
  private onPropertyChange(val: ProgramItem[]) {
    this.EmitSelectedPrograms(val);
    // emit property if diffirent program item selected and scroll will go to top
    Root.emit('new-program-edit-item-pane', true);
  }
  @Watch('selectAllItems')
  private onPropertySelectAllItemsChange(val: boolean) {
    if (this.programItems) {
      if (val === true) {
        this.selectedItems = [];
        this.selectedCheckboxCount = 0;
        this.programItems.forEach((item) => {
          this.selectedItems.push(item);
          this.selectedCheckboxCount += 1;
        });
        if (this.selectedItems.length === 1) {
          this.FillProgramDetails(this.selectedItems[0]);
        }
      } else {
        if (this.emptySelectedItems) {
          this.selectedItems = [];
          this.selectedCheckboxCount = 0;
        }
      }
      this.emptySelectedItems = true;
      // this.EmptyTrackAndTag();
    }
  }

  private GetDivPaddingStyle(index: number): string {
    if (this.isPartOfTrack(index)) {
      return 'padding:0px 10px;';
    }
    return '';
  }
  private isPartOfTrack(index: number): boolean {
    const pi = this.programItems ? this.programItems[index] : null;
    if (pi) {
      if (pi.Track) {
        return true;
      }
    }
    return false;
  }
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      if (this.showSectionModalPopup === this.WarningMessage) {
        this.resetProgramItemsBackgroundColor();
        this.updateSelectedItem(this.selectedProgramItem);
      }  else if (this.showSectionModalPopup === this.Notification) {
        this.programItemDeletedOperation(this.selectedItemId);
        this.$emit('program-item-cancel-operation', ProgramItem.createEmpty());
        // empty selected item array if deleted
        this.selectedItems = [];
      } else if (this.showSectionModalPopup === this.NotificationSessionQuestionUpdated) {
        // refresh program detail page
        this.FillProgramDetails(this.selectedItems[0]);
        Root.emit(
          'update-session-question-listing',
          true,
        );
      } else if (this.showSectionModalPopup === this.NotificationAddInformationUpdated) {
        Root.emit(
          'program-customcategory-item-added',
          {programQuestionDetails: this.programDetailInfo, showAddInformation: false },
        );
      } else if ((this.showSectionModalPopup === this.NotificationMultimediaUpdated) ||
                (this.showSectionModalPopup === this.NotificationMultimediaDeleteUpdated) ||
                (this.showSectionModalPopup === this.Notification360ImagesUpdated) ||
                (this.showSectionModalPopup === this.Notification360ImagesDeleteUpdated)
      ) {
        // refresh program detail page
        this.FillProgramDetails(this.selectedItems[0]);
      } else if (this.showSectionModalPopup === this.NotificationEvaluationQuestionUpdated) {
        // refresh program detail page
        this.FillProgramDetails(this.selectedItems[0]);
        Root.emit(
          'reload-evaluation-questions-data',
        );
      } else if (this.showSectionModalPopup === this.NotificationUpdateInformationListForProgramLinking) {
        this.FillProgramDetails(this.selectedItems[0]);
      } else if (this.showSectionModalPopup === this.NotificationUpdateInformationListForProgramLinkingBulk) {
        Root.emit('reload-programs-data');
        this.$emit('program-item-cancel-operation', ProgramItem.createEmpty());
        // empty selected item array if deleted
        this.selectedItems = [];
      } else if (this.showSectionModalPopup === this.VodVideoUploadInProgress) {
        // Root.emit('program-cancel-video-upload');
        this.updateSelectedItem(this.selectedProgramItem);
      } else if (this.showSectionModalPopup === this.NotificationVideoUploadedSuccessfully) {
        this.FillProgramDetails(this.selectedItems[0]);
        Root.emit('program-vod-video-uploading-user-is-me', true);
      } else if (this.showSectionModalPopup === this.NotificationVideoDeletedSuccessfully) {
        this.FillProgramDetails(this.selectedItems[0]);
      }
    }
  }
  // uncheck select all checkbox
  private UncheckSelectAllCheckbox() {
    Root.emit('program-uncheck-select-all');
    this.emptySelectedItems = false;
  }

  private updateSelectedItem(selectedProgramItem: ProgramItem) {
    this.UncheckSelectAllCheckbox();
    // make background white of selected program item in program list.
    this.resetProgramItemsBackgroundColor();
    this.selectedItems = [];
    if(selectedProgramItem !== null && selectedProgramItem !== undefined) {
      setTimeout(() => {
        selectedProgramItem.styleProgramDiv = CommonStyle.SelectedItemDivColor;
        selectedProgramItem.styleProgramTimeText = selectedProgramItem.styleProgramDescriptionText = CommonStyle.SelectedItemHeadingColor;
      }, 100);
      this.selectedItems.push(selectedProgramItem);
      this.selectedCheckboxCount = 1;
      this.FillProgramDetails(selectedProgramItem);
    }
  }

  private FillProgramDetails(selectedProgramItem: ProgramItem) {
    setTimeout(() => {
      Root.emit('programDetailLoading', true);
    }, 100);
    // get listing object in program detail - api calling
    let url = '';
    url = '/Program/GetProgramDetail?programId=' + selectedProgramItem.Id;
    FetchData(url,'')
      .then((response) => {
        if (response.ProgramId > 0) {
          const responseData = response;
          selectedProgramItem.modifedDate = responseData.Modified;
          selectedProgramItem.LinkedInformation =
            responseData.LinkableCustomCategories;
          selectedProgramItem.EvaluationQuestions =
            responseData.ProgramEvaluationDtos;
          selectedProgramItem.ProgramMedias = responseData.ProgramMedias;
          selectedProgramItem.ProgramMedias360 = responseData.ProgramMedias360;
          selectedProgramItem.SessionQuestions =
            responseData.ProgramQuestionDtos;
          selectedProgramItem.ProgramQuestionLinkExpirationDate =
            responseData.ProgramQuestionLinkExpirationDate;
          selectedProgramItem.ProgramQuestionPublishedLink =
            responseData.ProgramQuestionPublishedLink;
          selectedProgramItem.CustomCategoryFolders =
            responseData.CustomCategoryFolders;
          selectedProgramItem.VonageSessionData =
            responseData.VonageSessionData;
          selectedProgramItem.VodDetail =
            responseData.VodDetail;
          selectedProgramItem.CheckedInParticipantsCount = responseData.CheckedInParticipantsCount;
          selectedProgramItem.HasExternalZoomLink = responseData.HasExternalZoomLink;
          selectedProgramItem.ParticipantGroupIds = responseData.ParticipantGroupIds;
          if (selectedProgramItem.VirtualMeetingType ===  VirtualMeetingEnums.VonageString) {
            selectedProgramItem.IsRecordedSession = responseData.VonageSetting!.IsRecorded;
          } else {
            if (responseData.VodDetail !== null && responseData.VodDetail !== undefined) {
              selectedProgramItem.IsRecordedSession = responseData.VodDetail.IsRecorded;
            }
          }
          // emit values to components
          this.callrootlevelemits(selectedProgramItem);

          if (selectedProgramItem.VodDetail!.VodStreamingType === 1 && selectedProgramItem.VodDetail!.VodType === 'Mux') {
            selectedProgramItem.VirtualMeetingType = 'Mux';
          }
        } else {
          this.callrootlevelemits(selectedProgramItem);
          Root.emit('programDetailLoading', false);
        }
        this.selectedProgramItemValue = [];
        this.selectedProgramItemValue.push(selectedProgramItem);
        // this.EmitSelectedPrograms(this.selectedProgramItemValue);
      })
      .catch(() => {
        Root.emit('programDetailLoading', false);
      });
  }

  @Watch('editingProgramItem')
  private ChangeEditingProgramItem(val: ProgramItem) {

    let selectedProgram = this.selectedItems[0];
    if (this.selectedItems.length > 1) {
      selectedProgram = this.selectedProgramItem;
    }
    val.VirtualMeetingLink = val.VirtualMeetingLink === null ? '' : val.VirtualMeetingLink;

    selectedProgram.VirtualMeetingLink = selectedProgram.VirtualMeetingLink === null ? '' : selectedProgram.VirtualMeetingLink;

    if (ProgramItem.prototype.CompareProgramObjects({val1: val, val2: selectedProgram}) && !this.isVideoUploadinProgress) {

      if (this.selectedProgramItem !== null && this.selectedProgramItem !== undefined && this.selectedProgramItem.VodDetail !== null && this.selectedProgramItem.VodDetail !== undefined && this.selectedProgramItem.VodDetail.VODLatestStatus === 'VideoAssetCreated') {
        this.isVideoUploadinProgress = true;
      }
      this.updateSelectedItem(this.selectedProgramItem);
    } else {
      if (this.isVideoUploadinProgress) {
        this.isVideoUploadinProgress = false;
        this.modalPopupVisible = true;
        this.SetModalPopupDefaultButtonText();
        this.showSectionModalPopup = this.VodVideoUploadInProgress;
        this.modalPopupContent = this.$t('VirtualMeetings.AddVideo.ChangeScreenMessage').toString();
      } else {
        this.showSectionModalPopup = this.WarningMessage;
        this.modalPopupVisible = true;
        this.modalPopupContent = this.getMoveToAnotherProgramMessage();
        this.SetModalPopupDefaultButtonText();
        this.showCloseButton = true;
      }
    }
  }
  private SetModalPopupDefaultButtonText() {
    this.modalPopupShowCancelButton = true;
    this.modalPopupConfirmButtonText = this.$t('YesButton').toString();
    this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
  }
  private EmptyTrackAndTag() {
    Root.emit(
      'empty-track-tag',
    );
  }
  private selectItem(selectedProgramItem: ProgramItem) {
    if (this.selectedItems.length === 1) {
      this.$emit('change-program-item', {selectedItem: selectedProgramItem, moveToAnotherTab: false});
      this.selectedProgramItem = selectedProgramItem;
    } else {
      this.updateSelectedItem(selectedProgramItem);
    }
    this.EmptyTrackAndTag();
  }
  private callrootlevelemits(programDetailViewModel: ProgramItem) {
    Root.emit(
      'selected-participant-group-is',
      programDetailViewModel.ParticipantGroupIds,
    );
    Root.emit(
      'sessionQuestionData',
      programDetailViewModel.SessionQuestions,
    );
    Root.emit(
      'evaluationQuestionData',
      programDetailViewModel.EvaluationQuestions,
    );
    Root.emit(
      'linkedInformationData',
      programDetailViewModel.LinkedInformation,
    );
    Root.emit('image360Data', programDetailViewModel.ProgramMedias360);
    Root.emit('multimediaData', programDetailViewModel.ProgramMedias);
    Root.emit(
      'ProgramQuestionLinkExpirationDate',
      programDetailViewModel.ProgramQuestionLinkExpirationDate,
    );
    Root.emit(
      'ProgramQuestionPublishedLink',
      programDetailViewModel.ProgramQuestionPublishedLink,
    );
    Root.emit(
      'ProgramVonageSessionData',
      programDetailViewModel.VonageSessionData,
    );
    const vodData = VodMuxVideoData.createEmpty();
    vodData.VideoDetails = programDetailViewModel.VodDetail!;
    vodData.VirtualMeetingType = programDetailViewModel.VirtualMeetingType;
    Root.emit(
      'ProgramVodMuxVideoData',
      vodData,
    );
    Root.emit(
      'VodMuxVideoData',
      vodData,
    );
    Root.emit(
      'update-program-details',
      programDetailViewModel,
    );

    if (programDetailViewModel.VodDetail!.VODLatestStatus === 'VideoUploadCreated' || programDetailViewModel.VodDetail!.VODLatestStatus === 'VideoUploadAssetCreated') {
      Root.emit('virtual-meeting-meetapp-live-analytics-available', true);
    } else if (programDetailViewModel.VodDetail!.VODLatestStatus === 'VideoAssetReady') {
      Root.emit('virtual-meeting-meetapp-live-analytics-available', false);
    }
    // make loading false
    Root.emit('programDetailLoading', false);
    // update date to programedititempane local variable.
    // this.$emit("program-item-edit-operation", programDetailViewModel);
  }

  private checkProgramItems(val: ProgramItem, index: number, event: any) {
    this.UncheckSelectAllCheckbox();
    setTimeout(() => {
      this.resetProgramItemsBackgroundColor();
    }, 100);
    const programItemChecked = event.target.checked;
    if (programItemChecked) {
      this.selectedCheckboxCount += 1;
    } else {
      this.selectedCheckboxCount -= 1;
    }

    if (this.selectedCheckboxCount === 1) {
      if (programItemChecked) {
        this.selectItem(val);
      } else {
        this.temporarySelectedItems = this.selectedItems;
        this.temporarySelectedItems = this.temporarySelectedItems.filter(
          (x) => x.Id !== val.Id,
        );
        this.selectItem(this.temporarySelectedItems[0]);
      }
    }
  }

  private resetProgramItemsBackgroundColor() {
    if(this.programItems !== null && this.programItems !== undefined) {
      this.programItems.forEach(function(item) {
        item.styleProgramDiv = '#fff';
        item.styleProgramDescriptionText = '#000';
        item.styleProgramTimeText = '#8B8B8B';
      });
    }
  }
  // Redirect to specific program item by passing index of program item
  private RedirectToProgramItem(index: number) {
    setTimeout(() => {
      const element = document.querySelectorAll('[index="' + index + '"]')[0];
      if (element) {
        element.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth',
          inline: 'nearest',
        });
      }
    }, 1500);
  }
  // Open Create new Question Pane
  private OpenCreateNewQuestionpane() {
    this.$emit('create-new-program-click', '');
  }

  private darkColor(color: string){
    return color && CommonStyle.lightOrDark(color);
  }

  private beforeUnmount() {
    Root.off('program-item-bulk-imageUrl-operation');
    Root.off('program-item-bulk-delete-operation');
    Root.off('selected-program-item-notification-completed');
    Root.off('update-information-linking-in-programs');
    Root.off('update-tags-in-listing');
    Root.off('update-tracks-in-listing');
    Root.off('program-item-bulk-datetime-operation');
    Root.off('update-information-in-listing');
  }
}
export default toNative(ProgramListView);
